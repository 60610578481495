import { Link } from 'react-router-dom';
import styles from './styles.module.scss'
import { FC, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import React from 'react';
import ThankYou from '../ThankYou/ThankYou';


export const ContactForm: FC = () => {
    useEffect(() => emailjs.init("YOUR-PUBLIC-KEY-HERE"), []);
    const emailRef = React.useRef<HTMLInputElement | any>();
    const nameRef = React.useRef<HTMLInputElement | any>();
    const [isClose, setIsClose] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const serviceId = "YOUR-SERVICE-ID-HERE";
        const templateId = "YOUR-TEMPLATE-ID-HERE";
        try {
            await emailjs.send(serviceId, templateId, {
                name: nameRef.current.value,
                recipient: emailRef.current.value
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsClose(true);
            setName('');
            setEmail('');
            setText('');
        }
    };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };
    return (
        <div className={styles.background}>
            <div className={styles.container}>
                <button className={styles.top_button}>Smart solution</button>
                <h2>Need a consultation? <br />Let's talk.</h2>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.form_contacts}>
                        <div className={styles.form_name}>
                            <span>Name</span>
                            <input type="text" placeholder='Enter your name' value={name} ref={nameRef} onChange={handleNameChange} />
                        </div>
                        <div className={styles.form_number}>
                            <span>Email or phone number</span>
                            <input type="text" placeholder='Enter your email or phone number' value={email} ref={emailRef} name="user_email" onChange={handleEmailChange} />
                        </div>
                    </div>
                    <div className={styles.form_message}>
                        <span>Your message</span>
                        <textarea name="text" placeholder='Type your message here' value={text} onChange={handleTextChange} />
                    </div>
                    <div className={styles.form_accept}>
                        <label>
                            <input type="checkbox" required />
                            <span></span>
                        </label>
                        <span>I have read and accept the<Link to='/policies/policy'>Privacy Policy</Link></span>
                    </div>
                    <div className={styles.button_wrap}>
                        <button className={styles.form_submit} type="submit">Sent</button>
                    </div>
                </form>
            </div>
            {isClose ? <ThankYou close={() => setIsClose(false)} /> : null}
        </div>
    );
}

export default ContactForm;