import React, { useEffect } from 'react';
import OurServices from './Main/OueServices';
import Payment from './Paymet/Payment';
import Recurring from './Dispute/Dispute';
import Analytics from './Analytics/Analytics';
import ContactForm from '../ContactForm/ContactForm';
import style from './styles.module.scss'



function ServicesPage() {

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [])
    return (
        <div className={style.container}>
            <OurServices />
            <Payment />
            <Recurring />
            <Analytics />
            <ContactForm />
        </div>
    );
}

export default ServicesPage;