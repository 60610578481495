import styles from './styles.module.scss'
import React, { FC } from 'react';
import logo from '../../assets/logo wallibou.svg'
import { Link } from 'react-router-dom';

export const Footer: FC = () => {
    return (

        <div className={styles.container}>
            <div className={styles.container_top}>
                <div className={styles.container_address}>
                    <div className={styles.address}>
                        <div className={styles.title}>Address</div>
                        <div className={styles.address_current}>Suite 305, Griffith Corporate <br />
                            Centre, Kingstown, St.Vincent  <br />
                            and the Grenadines </div>
                    </div>
                    <div className={styles.registration}>
                        <div className={styles.title}>Registration number</div>
                        <div className={styles.address_current}>201922390D</div>
                    </div>
                </div>
                <div className={styles.links}>
                    <div className={styles.title}>Quick links</div>
                    <div className={styles.links_current}>
                        <Link to='/'>Main</Link>
                        <Link to='/services'>Services</Link>
                        <Link to='/integration'>Integration</Link>
                        <Link to='/policies'>Policies</Link>
                    </div>
                </div>
                <div className={styles.contacts}>
                    <div className={styles.contacts_number}>
                        <div className={styles.title}>Call us directly</div>
                        <div className={styles.address_current}>(784) 456-1287</div>
                    </div>
                    <div className={styles.contacts_email}>
                        <div className={styles.title}>Mail us directly</div>
                        <div className={styles.address_current}>info@wallibou.biz</div>
                    </div>
                </div>
            </div>
            <div className={styles.container_botton}>
                <div className={styles.container_botton_imgWrap}><img alt='' src={logo} /></div>
                <div className={styles.container_botton_links}>
                    <Link to="/policies/policy">Privacy Policy</Link>
                    <Link to="/policies/terms">Terms of Use</Link>
                    <Link to="/policies/aml&kyc">AML & KYC Policy</Link>
                </div>
            </div>
            <div className={styles.container_endLine}>
                <span>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here.
                    <br /> You must not use this Website if you disagree with any of these Website Standard</span>
            </div>
        </div>
    );
}

export default Footer;
