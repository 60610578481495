import styles from './styles.module.scss'
import { FC } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'


type ThankYouType = {
    close: () => void
}

export const ThankYou: FC<ThankYouType> = ({ close }) => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);

    return (
        <div data-aos="zoom-in" data-aos-delay={400} className={styles.container}>
            <h5>Tnank you!</h5>
            <p>Your form has been successfully submitted. We will contact you soon.</p>
            <button onClick={close}>Okay</button>
        </div>
    );
}

export default ThankYou;