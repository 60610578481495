import { NavLink, Route, Routes } from 'react-router-dom';
import styles from './styles.module.scss'
import { FC, useEffect } from 'react';
import { TermsOfUse } from './PoliciesItems/TermsOfUse';
import { PrivacyPolicy } from './PoliciesItems/PrivacyPolicy';
import { AKPolicy } from './PoliciesItems/AKPolicy';

export const Policies: FC = () => {

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [])
    const scroll = () => {
        window.scrollTo({ top: 0 });

    }

    return (

        <div className={styles.container}>
            <h2>Policies</h2>
            <div className={styles.buttons_block}>
                <NavLink to='/policies/terms' className={styles.button} onClick={scroll}>Terms of use</NavLink>
                <NavLink to='/policies/policy' className={styles.button} onClick={scroll}>Privacy policy</NavLink>
                <NavLink to='/policies/aml&kyc' className={styles.button} onClick={scroll}>AML & KYC Policy</NavLink>
            </div>
            <div className={styles.container_routes}>
                <Routes >
                    <Route path='/terms' element={<TermsOfUse />} />
                    <Route path='/policy' element={<PrivacyPolicy />} />
                    <Route path='/aml&kyc' element={<AKPolicy />} />
                </Routes>
            </div>
        </div>

    );
}
