import styles from './styles.module.scss'
import React, { FC } from 'react';
import umbrella from '../../../assets/umbrella-min.png'
import guide from '../../../assets/travel-guide-min.png'
import boat from '../../../assets/Speedboat.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const Analytics: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);
    return (
        <div className={styles.container}>
            <div data-aos="zoom-in" data-aos-delay={400} className={styles.container_top} id='fraud'>
                <div className={styles.img_wrap}><img src={umbrella} alt="" /></div>
                <div className={styles.integration}>
                    <h2>Fraud detection and <br />
                        prevention measures </h2>
                    <span>Fraud detection and prevention measures are essential for
                        any online business that accepts payments. Payment
                        gateway providers offer various fraud detection and
                        prevention measures, such as 3D Secure, CVV verification,
                        and fraud scoring, to help you detect and prevent fraudulent
                        transactions. These measures can help you protect your
                        business from financial losses and maintain customer trust.</span>
                </div>
            </div>
            <div data-aos="zoom-in" data-aos-delay={400} className={styles.container_botton}>
                <div id='analytics'>
                    <div className={styles.title_wrap}>
                        <h2>Analytics and reporting  <br />tools </h2>
                        <img src={boat} className={styles.boat} alt=''></img>
                    </div>
                    <span>Analytics and reporting tools are valuable for businesses that <br />
                        want to track and analyze their payment data. Payment <br />
                        gateway providers offer various analytics and reporting tools,<br />
                        such as sales reports, transaction reports, and chargeback <br />
                        reports, which can help you gain insights into your business <br />
                        performance and identify areas for improvement. These tools <br />
                        can also help you make data-driven decisions and optimize <br />
                        your payment processes for maximum efficiency and <br />
                        profitability.</span>
                </div>
                <div className={styles.img_wrap}><img src={guide} alt="" /></div>
            </div>
        </div>
    );
}

export default Analytics;