import styles from './styles.module.scss'
import { FC } from 'react';

export const TermsOfUse: FC = () => {

    return (
        <div className={styles.container}>
            <h3>Terms of use</h3>
            <p>These are the entire terms and conditions that apply to the access and/or use of Wallibou Management
                LTD web-site. Please read these Terms and Conditions completely and carefully before accessing and/or
                using web-site. You must read, agree with and accept all of the terms and conditions contained in this
                Agreement without modifications, which include those terms and conditions expressly
                set out below and those incorporated herein by reference, before you may become a Customer of Wallibou Management LTD</p>
            <p>IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT USE OR
                ACCESS OUR SERVICES AND INFORM US IN WRITING IMMEDIATELY.</p>
            <h5>1.	MAIN DEFINITIONS</h5>
            <p>These Terms and conditions of Use is a legal agreement between Wallibou Management LTD and its Customers (“Agreement”).
                Wallibou Management LTD is a company organized and existing under the laws of Saint Vincent and the Grenadines under number 27017 BC and having its legal place of business at Suite 305, Griffith Corporate Centre, Kingstown St. Vincent and the Grenadines, (“Company” or “Wallibou”). Wallibou conducts its business activity (“Service(s)”).
                The Client/Customer of Wallibou is a legal entity to whom Wallibou provides its Services in line with this Agreement. Wallibou and the Customer together referred to as the Parties.
            </p>
            <h5>2.	SUBJECT OF THE TERMS AND CONDITIONS</h5>
            <p>This Agreement sets out the terms and conditions for provision of the Services on the Platform.
                Upon entering into business relations with Wallibou, the Client asserts that it has read this Agreement, other Company's policies, Wallibou’s Fees set by Wallibou and agrees thereto.
                The Agreement shall constitute an integral part of all transactions between the Parties. In case of any discrepancy between the Agreement and the norms stipulated in any agreement between the Parties, the latter shall prevail.</p>
            <h5>3.	TERMS AND CONDITIONS</h5>
            <p>Concluding the agreement, the Customer hereby represents and warrants to Wallibou that:
                <br />  -	The Customer has full legal capacity and ability to act in order to execute and perform right and obligations under the agreement;
                <br />  -	The concluding the agreement does not violate any laws or regulations applicable to the Customer;
                <br />  -	The agreement and all its consequences are binding upon the Customer and do not cause infringements of the laws of Saint Vincent and the Grenadines or the laws of the place of performance of the services;
                <br />  -	All information supplied by the Customer to Wallibou, including information on its activities, financial condition, and location, is true and is not misleading. All documents and notices supplied by Customer to Wallibou are true and valid. Customer is aware of criminal responsibility for supplying Company with false information;
                <br />  -	The Customer shall notify Wallibou promptly, but not later than within 2 weeks, of any changes to the information provided;
                <br />  -	The Customer has not offered, promised or given something or any preference to any Company’s employee, directly or indirectly, for the latter to act or abstain from any action in infringement of his duties;
                <br />  -	The Customer is not engaged in money laundering, that is, concealing or disguising the criminal origin of funds or other estate obtained as a result of criminal offence, or in terrorism financing, and the Customer’s funds on deposit at Wallibou have not been obtained through unlawful means;
                <br />  -	Wallibou shall be entitled to verify the representation and certification and other information supplied by the Customer at any time. <br /> <br />

                <span> The Customer may only conclude an agreement with Wallibou on provision Services and only if it is permitted to do so in Customer’s country of residence.</span>

                <p> In accordance with Wallibou’s AML/CFT obligations and internal procedures, Wallibou may ask Customer at any time to confirm the accuracy of Customer’s information or to provide documents or other evidence. </p>

                <p> All transactions between the Customer and Wallibou shall be performed by wire transfer or other electronic means, in which the identities of both parties can be verified by Wallibou and which Wallibou, at its sole discretion, shall deem appropriate. </p>

                <p> The Client/Customer acknowledges and agrees that the Services on the Platform are not a marketplace, and any contract of sale made through the Services is directly between the Client and it’s Customer. The Client is the seller of record for all items it sells through the Services. The Client is responsible for the creation and operation of the goods and services that it may sell through the Services, and all aspects of the transactions between the Client and it’s customer(s). This includes, but is not limited to, authorizing the charge to the Client’s customer in respect of the customer’s purchase, refunds, returns, fulfilling any sales or customer service, fraudulent transactions, required legal disclosures, regulatory compliance, alleged or actual violation of applicable laws (including but not limited to consumer protection laws in any jurisdiction where the Client offers products or services for sale), or Client’s breach of these Terms of Use. The Client represents and warrants that the goods and services it sells through the Services will be true, accurate, and complete, and will not violate any applicable laws, regulations or rights of third parties. For the avoidance of doubt, Paytoplay will not be the seller or merchant or record and will have no responsibility for items sold to customers through the Services. <br />
                    The Client is solely responsible for the goods or services that it may sell through the Services (including description, price, fees, tax that the Client calculates, defects, required legal disclosures, regulatory compliance, offers or promotional content), including compliance with any applicable laws or regulations. </p>

                <p> The Client may not use the Services for any illegal or unauthorized purpose nor may the Client, in the use of the Service, violate any laws in its jurisdiction (including but not limited to copyright laws), the laws applicable to the Client in its customer’s jurisdiction, or the laws of the Republic of Estonia. The Client will comply with all applicable laws, rules and regulations (including but not limited to obtaining and complying with the requirements of any license or permit that may be necessary to the Client) in Client’s use of the Service and performance of obligations under the Terms of Use. </p>
            </p>
            <h5>4.	LIMITATION OF WARRANTIES</h5>
            <p>Wallibou makes no representations or warranties that the Platform or Services, or any materials contained therein, will be uninterrupted, timely, secure or error-free; nor do Wallibou makes any representations or warranties as to the quality, suitability, truth, usefulness, accuracy, or completeness of the Platform or Services or any of the materials contained therein. <br />
                Wallibou’s Services and Platform are provided on an "as is" and "as available" basis. Wallibou expressly disclaims and Customer waives all warranties of any kind, whether expressed or implied or statutory, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement as to Wallibou’s Services, Platform and the information, content and materials contained therein. <br />
                The Customer acknowledges that the information Customer stores or transfers through Wallibou’s Platform or Services may become irretrievably lost or corrupted or temporarily unavailable due to a variety of causes, including software failures, protocol changes by third party providers, Internet outages, force majeure or other disasters, scheduled or unscheduled maintenance, or other causes either within or outside Wallibou’s control. Customer is solely responsible for backing up and maintaining duplicate copies of any information Customer stores or transfers through Wallibou’s Services.
            </p>
            <h5>5.	LIMITATION OF LIABILITY AND INDEMNIFICATION</h5>
            <p>Wallibou will not be liable for any loss, liability or cost suffered or incurred by the Client as a result of providing Services to the Client unless the loss, liability or cost is caused by Company’s gross negligence, willful default or fraud committed while acting on Client’s instructions.</p>
            <p> Neither Wallibou nor any third party who acts on Company’s behalf in providing a Service to the Client, whether affiliated to Wallibou or not, nor Wallibou or its directors, officers, servants, agents or representatives, will be liable to the Client (except in the case of fraud) for any consequential, indirect, special, incidental, punitive or exemplary loss, liability or cost which the Client may suffer or incur arising out of Company’s acts or omissions under this Agreement, howsoever the loss, liability or cost is caused and regardless of whether it was foreseeable or not.</p>
            <p>Without prejudice to any other terms of this Agreement relating to the limitation of liability and provision of indemnities, the following shall apply in particular to provision of Services under this Agreement: any kinds of the System errors: malfunction or failure of transmission, communication or computer facilities, or any transmission errors, technical faults, malfunctions, illegal intervention in network equipment, network overloads, malicious suspension of access by third parties, Internet malfunctions, interruptions, etc. <br />
                - any delays, inaccuracies, errors or omissions in any data provided to the Customer in connection with the Platform or use of related Services. <br />
                - any viruses, worms, software bombs or similar malicious code items introduced into the Customer`s information system and/or Service provided by Wallibou, provided that Wallibou has taken reasonable steps to prevent any such incident. <br />
                - any loss, liability or cost whatsoever arising from any unauthorized use of the Platform or related Services. any software and/or service of any third-party is used by Wallibou in the enforcement of any of the provisions of this Agreement. <br />
                - any disruption or impairment of the Platform or the Service or for disruptions or impairments of intermediary services on which Wallibou rely for the performance of Wallibou’s obligations hereunder. <br /> - any claims, losses, damages, costs or expenses, lost opportunity to transact resulting from any Force Majeure circumstances which under the control of Wallibou. To the maximum extent permitted by applicable laws, Customer also agree to defend and indemnify Wallibou should any third party be harmed by Customer’s illegal actions. Wallibou’s liability will be limited to the fullest possible extent permitted by applicable law.</p>
            <h5>6.	THIRD PARTY PROVIDERS</h5>
            <p>The Customer does hereby agree and acknowledge that the Platform, and related software and Services may be provided by an external third-party licensor. Wallibou does not bear any responsibility as to the Platform and related software and Services provided by external third-party licensors. Wallibou make no representations or warranties that the technological facility (the Platform), and related software and Services will be uninterrupted, timely, secure, or error-free. <br />
                The Customer hereby agrees and acknowledges that the functionality of the Platform, its configuration and content may be modified by an external third-party licensor.
            </p>
            <h5>7.	APPLICABLE LAW AND JURISDICTION</h5>
            <p>This Agreement, and the rights and obligations of the parties hereto, shall be governed by and enforced in all respects by the laws of Saint Vincent and the Grenadines. <br />
                The Customer agrees that any civil action, arbitration or other legal proceeding between Wallibou or its employees or agents, and the Customer arising out of or relating to this Agreement shall be brought, heard and resolved only by a court located in Company’s jurisdiction. <br />
                Wallibou may offer to settle any claim in an arbitration.
            </p>
            <h5>8.	FORCE MAJEURE</h5>
            <p>Wallibou is not responsible for any damages caused by delay or failure to perform its obligations under the Agreement in the event that said delay or failure is due to fires; strikes; floods; power outages or failures; acts of God or the state’s enemies; lawful acts of public authorities; any and all acts that are regarded as Force Majeure in legal practice.</p>
            <h5>9.	INTELLECTUAL PROPERTY RIGHTS</h5>
            <p>All copyright and other intellectual property rights of all content and other materials contained on Wallibou’s Website or provided in connection with the Services are the proprietary property of Wallibou. <br />
                Wallibou grants to the Customer a limited, nonexclusive and non-sub-licensable permission to access and use the data made available by Wallibou for personal or internal business use of the Customer. Such permission does not include any unauthorized distribution or use, modification or public display of any data made available by Wallibou. The permission granted under this provision will be automatically terminated if Wallibou suspends or terminates Customer’s access to the Services. <br />
            </p>
            <h5>10.	 AML POLICY</h5>
            <p> Wallibou is committed to providing its Customers safe and compliant Services. For this purpose Wallibou has developed an Anti-Money Laundering Policy, a summary of which is available on the Platform.</p>
            <h5>11.	 AMENDMENTS</h5>
            <p>The Customer understands and agrees that Wallibou may amend or change this Agreement at any time at its sole discretion. Wallibou will post the amendment or an updated version of the Agreement on the Platform with an indication of the last update date. The Customer agrees to be bound by the terms of such amendment or change on the earlier of: <br />
                -	1 (one) business day after Wallibou has posted such amendment as specified above; or <br />
                -	on such occasion when the Customer executes any transaction on the Platform; <br />
                All such amendments and changes shall apply equally to all Customers, including those who accepted this Agreement before the effective date of the amendments or changes. <br />
                If the Customer objects to a part or all of the Agreement, then the Customer must stop using the Services of Wallibou immediately. By continuing to use Wallibou’s Services and the Platform, Customer acknowledge that Customer agree to be bound by provisions of this Agreement (with all changes and amendments hereto).
            </p>
            <h5>12.	TERMINATION</h5>
            <p>This Agreement shall be in force until its termination, and may be terminated by the Customer at any time, provided that the Customer has no liabilities held by or owed to Wallibou. <br />
                Wallibou may at any time suspend or terminate this Agreement without notice in the following cases: <br />
                -	Customer breaches any condition of this Agreement or other legally binding obligations between Wallibou and Customer; <br />
                -	Customer violate or Wallibou have reason to believe that Customer are in violation of any law or regulation that is applicable to Customer’s use of Wallibou’s Services; or <br />
                -	Wallibou have reason to believe that Customer are in any way involved in any fraudulent activity, money laundering, terrorism financing or other criminal activity. <br />
            </p>
            <h5>13.	MISCELLANEOUS</h5>
            <p>Wallibou does not facilitate or provide trading or investment or brokerage accounts or facilities, nor does Wallibou provide investment or any other financial advice. <br />
                Wallibou may communicate with the Customer by sending information to Customer’s email or posting information on the Website, in which case the information will be treated as received by the Customer when it is posted by Wallibou. <br />
                Wallibou may also contact Customer via email address of the Customer. Notices sent by email will be deemed to have been received at the time of transmission as shown by the sender’s records (or if sent outside business hours, at 9 am on the first business day following dispatch). <br />
                <br />
                <br />
                Privacy and data protection are governed by Wallibou’s Privacy policy, which is available on the web-site.
            </p>
            <h5>14.	COMPLAINTS AND SUPPORT</h5>
            <p>If any conflict situation arises when the Customer reasonably believes Wallibou to be in breach of one or more terms of this Agreement as a result of any action or failure to act, the Customer has the right to file a complaint with Wallibou within one day after the grievance has arisen. The customer may send his complaint to: <address> info@wallibou.biz</address> <br />
                If the Customer has any questions or concerns related to the Agreement, their rights and/or obligations, they can contact Wallibou according to the following contact information:
                <br />
                <br />
                Wallibou Management LTD <br />
                Suite 305, Griffith Corporate Centre, Kingstown St. Vincent and the Grenadines <br />
                <address> info@wallibou.biz</address> <br />
                <br />
                Last update: January 01, 2024
            </p>
        </div>
    );
}
