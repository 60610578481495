import styles from './styles.module.scss'
import React, { FC, useState } from 'react';
import close from '../../assets/close.svg'
import { Link } from 'react-router-dom';

type Props = {
    setSignInOpen?: any
    fromMobile?: any
}
export const SignIn: FC<Props> = ({ setSignInOpen }) => {
    const [noLogin, setNoLogin] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const validateForm = () => {
        if (login === '') {
            setError('Please fill in all fields')
        } else {
            setError("")
        }
    };

    const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(e.target.value);
    };
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        validateForm();
    };
    return (
        <div className={styles.bg}>
            <div className={styles.modal}>
                <div className={styles.modal_title}>
                    <h3>Authorization</h3>
                    <Link to='/' ><img src={close} alt="close" onClick={() => setSignInOpen(false)}/></Link>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={styles.modal_form}>
                        <p>Login</p>
                        <input type="text" placeholder='Enter your login' value={login} onChange={handleLoginChange} />

                    </div>
                    <div className={styles.modal_form}>
                        <p>Password</p>
                        <input type="password" placeholder='Enter your password' value={password} onChange={handlePasswordChange} />
                    </div>
                    <button type='submit' onClick={() => setNoLogin(true)}>Okay</button>
                    <span>I don't have an account yet</span>
                    {error && <div className={styles.fill}>Please fill in all fields</div>}
                    {noLogin ? <div className={styles.error}>Wrong login or password</div> : null}
                </form>
            </div>
        </div>
    );
}

export default SignIn;