import React, { FC } from 'react';
import styles from './styles.module.scss'
import logo from './../../assets/logo wallibou.svg'
import { Link } from 'react-router-dom';
import SignIn from '../SignIn/SignIn';



export const Header: FC = () => {
    const [signInOpen, setSignInOpen] = React.useState(false)
    const scrollToTop = () => {
        window.scrollTo({ top: 0 })
    }
    return (
        <div className={styles.header}>
            <Link to='/'><img src={logo} alt='' className={styles.logo} onClick={scrollToTop}></img></Link>
            <div className={styles.navigation}>
                <Link className={styles.links} to='/services' onClick={scrollToTop}>Our services</Link>
                <Link className={styles.links} to='/integration' onClick={scrollToTop}>Integration</Link>
                <Link className={styles.links} to='/policies/terms' onClick={scrollToTop}>Policies</Link>
            </div>
            <button className={styles.SignIn} onClick={() => setSignInOpen(true)}>Sign in</button>
            {signInOpen ? <SignIn setSignInOpen={setSignInOpen} /> : null}
        </div>
    );
}

export default Header;
