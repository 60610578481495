import React, { useEffect } from 'react';
import { Integration } from './main/Integration';
import styles from './styles.module.scss'

function IntegrationPage() {

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [])

    return (
        <div className={styles.container}>
            <Integration />
        </div>
    );
}

export default IntegrationPage;