import { Link } from 'react-scroll';
import ContactForm from '../../ContactForm/ContactForm';
import ApplicationForm from '../ApplicationForm/ApplicationForm';
import styles from './styles.module.scss'
import { FC } from 'react';

export const Integration: FC = () => {

    return (
        <>
            <div className={styles.container}>
                <h2 className={styles.container_title}>Integration</h2>
                <div className={styles.buttons_block}>
                    <Link to="api" offset={-210}> <button className={styles.button} >REST API</button></Link>
                    <Link to="personalAccount" offset={-290}> <button className={styles.button}>Personal account</button></Link>
                    <Link to="registration"> <button className={styles.button}>Registration</button></Link>

                </div>
                <div id='api' className={styles.description}>
                    <h3 >REST API</h3>
                    <div className={styles.span_block}>
                        <span>Wallibou REST API supports the ability to carry out purchases, recurring payments, refunds, and pay-outs. Wallibou gateway allows users to process card payments from the following schemes: Maestro, Mastercard, and Visa, American Express (AMEX), JCB, CUP, and many more. We also have the capability for other alternative payment methods (APM's). For issuing payments using REST APIs, you need to develop your own payment interface with full UI control. For payment processing using card payments, your payment interface needs to be PCI-compliant</span>
                        <span>
                            Integration through Wallibou REST API allows enabling purchases, recurring payments, refunds, and pay-outs. Our gateway accepts card payments from popular systems like Maestro, Mastercard, Visa, American Express (AMEX), and others, as well as alternative payment methods. If you need to issue payments using REST APIs, you should develop your own payment interface with complete UI control. For processing card payments, your payment interface must be compatible with PCI. The merchant's web service sends a request to the API endpoints during payment processing. Our payment gateway receives the request, the server processes it, and redirects it to the third parties. This responds with a callback containing the final payment outcome.
                        </span>
                        <span>The merchant's web service sends a request to the API endpoints when performing a payment. Our payment gateway receives the request, and the server processes that request and redirects it to the third parties participating in the payment and responds with a callback containing the final payment result.</span>
                    </div>
                </div>
                <div id='personalAccount' className={styles.account}>
                    <h3  >Personal account</h3>
                    <p>The "personal account" feature is a handy tool for those who want to efficiently manage their finances and take advantage of the integration. In your personal account, you can control the integration, view transactions, and monitor payment statistics, which allows you to track your financial activities and receive real-time information about your business.</p>
                </div>
                <div id='registration'>
                    <ApplicationForm />
                </div>
                <ContactForm />
            </div>

        </>
    );
}
