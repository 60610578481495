import { Link } from 'react-scroll';
import styles from './styles.module.scss'
import React, { FC } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const OurServices: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);
    return (
        <div data-aos="zoom-in" data-aos-delay={300} className={styles.container}>
            <h2>Our services</h2>
            <div>
                <div className={styles.buttons}>
                    <div className={styles.sky_buttons}>
                        <Link to="payment" offset={-100} smooth={true} duration={500}> <button className={styles.hover}>Payment gateway integration</button> </Link>
                        <Link to="recurring" offset={-150} smooth={true} duration={500}> <button className={styles.hover}>Recurring billing options</button></Link>
                    </div>
                    <div className={styles.blue_buttons}>
                        <Link to="dispute" smooth={true} duration={500}> <button className={styles.hover}>Dispute resolution services</button></Link>
                        <Link to="fraud" offset={-150} smooth={true} duration={500}> <button className={styles.hover}>Fraud detection and prevention measures</button></Link>
                    </div>
                    <div className={styles.dark_button}>
                        <Link to="analytics" offset={-150} smooth={true} duration={500}> <button className={styles.hover}>Analytics and reporting tools</button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurServices;