import { Link } from 'react-router-dom';
import styles from './styles.module.scss'
import { FC, useEffect, useState } from 'react'
import { useLocalStorage } from '../../utils/useLocalStorage';
import { classNames } from '../../utils/classnames';



export const Cookies: FC = () => {
    const [agreed, setAgreed] = useLocalStorage('agreedCookies', false)
    const [classes, setClasses] = useState('')
    useEffect(() => {
        setClasses(classNames({ [styles.dNone]: agreed, [styles.o1]: !agreed }))
    }, [agreed])
    return (
        <div className={`${styles.cookies} ${classes}`}>
            <p>We care about your data, and we’d love to use cookies to make your experience better <Link to='/policies/policy'>Privacy policy</Link></p>
            <button onClick={() => setAgreed(!agreed)}>Okay</button>
        </div>
    );
}
export default Cookies;

