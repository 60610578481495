import styles from './styles.module.scss'
import React, { FC } from 'react';
import mountain from '../../../assets/mountain-peak-min.png'
import smile from '../../../assets/SmilingFaceWithHeartEyes.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const OurWay: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);
    return (
        <div className={styles.manage}>
            <div data-aos="fade-up" data-aos-delay={400} className={styles.manage_contentWrap}>
                <button className={styles.top_button}>Smart solution</button>
                <div className={styles.titleWrap}>
                    <h2>Our own way of working.</h2>
                    <img src={smile} className={styles.manage_img_smile} alt=''></img>
                </div>
                <p>The technologies we have developed enable us to process transactions quickly and
                    without mistakes, which considerably reduces the risks for our clients. We also
                    provide a wide range of integration capabilities, which makes it easy to adapt our
                    solutions to your existing systems.</p>
                <div className={styles.botton_buttons}>
                    <p>Reliable partner </p>
                    <p>Technologies efficiency</p>
                    <p>High accuracy</p>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-delay={400} className={styles.manage_imgWrap}>
                <img src={mountain} className={styles.manage_img_mountain} alt=''></img>
            </div>
        </div>
    );
}

export default OurWay;