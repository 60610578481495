import styles from './styles.module.scss'
import { FC } from 'react';

export const PrivacyPolicy: FC = () => {


    return (
        <div className={styles.container}>
            <h3>Privacy Policy</h3>
            <h5>1.	GENERAL DEFINITION</h5>
            <p>Wallibou Management LTD (hereinafter called the "Company") is an entity incorporated under the Limited Liability Companies Act, Chapter 149 of the Revised Laws of Saint Vincent of the Grenadines, 2009, with the following registrations: Company Number 27017 BC 2023. <br />
                Website Platform - a website that is operated by the Company and available at https://wallibou.biz <br />
                <br />
                Privacy Policy – the current document <br />
                <br />
                Customer - a legal entity that has read and agreed to the Terms of use of Wallibou Management LTD and uses services of the Company provided through the Website Platforms. <br />
                <br />
                GDPR - the General Data Protection Regulation (EU) 2016/679. <br />
                <br />
                KYC or Due Diligence - documents that are requested by Wallibou Management LTD from the Customers in order to identify the Customer and comply with applicable laws. <br />
                <br />
                Personal data means any information relating to Customer, which identifies or may identify a Customer and includes the following categories: Personal identification information: name, e-mail, phone number, mailing address, ID or other documents and other details; Payment information: bank information, card information, transaction history; Other data collected by Cookies. <br />
                <br />
                The Company understands that confidentiality and security of your personal information is important. That is why we have developed specific policies and practices designed to protect the privacy of your personal information. <br />
                By opening an account with the Company or by using the products and services available through the Company, you have consented to the collection and use of your personal information in accordance with the privacy notice set forth below. We encourage you to read this privacy notice carefully. <br />
            </p>
            <h5>2. PRIVACY POLICY STATEMENT AND PRINCIPLES. INFORMATION COLLECTING</h5>
            <p>The Company does not sell customer lists or customer email addresses to third party marketers. <br />
                The Company shall use and process the Customer’s personal data for the following purposes: <br />
                to provide Services under the Agreement; <br />
                to identify and verify the information about the Customer; <br />
                to comply with the Company’s legal obligations (e.g. KYC/AML requirements); <br />
                to prevent any fraud and scam activity; <br />
                to provide the Customer information on the Company’s products, news and updates; <br />
                other purposes as may be required by the Company’s policy or applicable law. <br />
                <br />
                In order to provide services and to comply with regulatory requirements, the Company collects certain personal, non-public information from you. This includes information: <br />
                name, e-mail, phone number, mailing address, ID or other documents and other details; <br />
                Payment information: bank information, card information, transaction history; <br />
                Other data collected by Cookies. <br />
                The Customer understands that the Company will not be able to provide the Services to Customer if he/she refuses to provide the Company information according to the Company’s KYC/AML requirements for verification of identity and ongoing monitoring of activity. In some cases, the Company may require additional details and documents for KYC and AML/CTF purposes. <br />
                <br />
                Company’s website uses small files known as cookies to enhance its functionality and improve Customer’s experience. <br />
                <br />
                Cookies are bits of textual information that are sent electronically from a web server to your browser and are stored on your computer. They do not identify you individually or contain personal information about you, unless you have identified yourself or provided the information by, for example, opening an account or registering for an online service. The Company may use cookies to measure and identify website traffic patterns and to track the performance of web features and advertisements. By providing the Company with a better understanding of how you and others use the Company’s website and other web services, cookies enable the Company to improve the navigation and functionality of its website and to present you with the most useful information and offers. The Company may share information obtained from cookies with its employees, agents, and affiliates, but does not sell such information to unaffiliated third parties. The Company may permit other companies or their third party ad servers to set cookies on your browser when you visit the Company’s website. Such companies generally use these cookies as we do. We also use cookies to improve the performance of our advertising on other websites. From time to time, we may use third party vendors, such as Google and DoubleClick, to display our ads over the internet to you, based on your previous use of our website. <br />
                <br />
                If Customer rejects cookies, Customer may still use the Platform. <br />
                <br />
                This Privacy Policy regulates and applies to all personal information held by Wallibou Management LTD (herein after the Company) as the controller of Customer's personal data and goes into detail on how the data are processed, including the measures taken to ensure that Customers' data is safe and secure. The terms of this Policy will apply to all personal information provided to the Company, and will continue to apply for as long as the Company hold Customers' personal data in terms of the applicable law. The Company is compliant with the applicable Saint Vincent and the Grenadines (SVG) and International laws for the Prevention of Money Laundering and Terrorist Financing, GDPR as well as other data protection legislation applicable in Saint Vincent and the Grenadines. <br />
                The Customer understands that the Company will not be able to provide the Services to Customer if he/she refuses to provide the Company information according to the Company’s KYC/AML requirements for verification of identity and ongoing monitoring of activity. In some cases, the Company may require additional details and documents for KYC and AML/CTF purposes.
            </p>
            <h5>3.CUSTOMER’S INFORMATION VERIFICATION AND PROCESSING</h5>
            <p>Except for the Company that collects, stores and processes Customers’ personal data, the following third parties acting on Company’s behalf (“Service Provider”), may collect, process and store personal data provided by the Customer: Company's affiliates, agents and representatives; Company's contractors providing software for identity verification and anti-fraud purposes; Company's contractors providing information from publicly accessible sources (for instance on sanctions); Company's financial service providers through which it facilitates transactions; law enforcement or regulatory agencies if required or permitted by law. <br />
                In accordance with the GDPR any Service Provider that processes the Customers’ personal data is obliged to: act on the written instructions of the Company (unless required by law to act without such instructions); take appropriate measures to ensure the security of processing; delete or return of all personal data as requested by the Company; perform other obligations as required by the GDPR and applicable law. <br />
                The Company has a regulatory obligation to supervise and effectively oversee the outsourced functions and to act appropriately when it determines that the Service Provider is not performing the said functions effectively and in accordance with the applicable legislation. <br />
                <br />
                The Company may use or disclose personal information without Customer’s consent only in certain circumstances: <br />
                <br />
                if required by law or by order of a court, administrative agency, or other government authority; <br />
                if the disclosure is necessary to protect the rights, privacy, property, or safety of Customers; <br />
                if the Company believes the information is related to a breach of the Agreement or violation of the law, that has been, is being, or is about to be committed; <br />
                if it is necessary for fraud protection, risk reduction, or the establishment or collection of funds owed to the Company; <br />
                for other reasons allowed or required by law;<br />
                When the Company is required or permitted to disclose information without consent, Company will not disclose more information than necessary to fulfil the disclosure purpose.
            </p>
            <h5>4.CUSTOMER’S RIGHTS</h5>
            <p>Customer has the following rights: <br />
                <br />
                to request copies of the it’s personal data;<br />
                to request the Company to rectify information the Customer deems to be inaccurate or incomplete;<br />
                to request the Company to erase the Customer’s personal information;<br />
                to request the Company to restrict the processing of the Customer’s information;<br />
                to object to the processing of the Customer’s personal data;<br />
                other rights foreseen by the GDPR and applicable law.<br />
                The Company must provide a copy of the information free of charge. However, the Company can charge a “reasonable fee” when a request is unfounded or excessive, particularly if it is repetitive.
            </p>
            <h5>5.DATA SECURITY</h5>
            <p>The Company has implemented necessary security measures to comply with acknowledged international security standards. <br />
                <br />
                When the Company receives the Customer's information, the Company implements strict security measures and procedures to avoid unauthorized access from any third party. <br />
                <br />
                The Company undertakes appropriate technical, organizational and administrative security measures to protect any information it holds in its records from loss, misuse, and unauthorized access, disclosure, alteration and destruction. However, the Company cannot guarantee complete security of Customer’s data. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of Customer information at any time. <br />
                <br />
                Customers must prevent unauthorized access to Customer’s profile and personal information by selecting and protecting Customer’s password appropriately and limiting access to Customer’s computer or device and browser by signing off after the Customer finished accessing Customer’s account. <br />
                <br />
                Transmission of information via regular email exchange is not always completely secure. The Company however exercises all possible actions to protect Customers’ personal data, yet it cannot guarantee the security of Customer data that is transmitted via email; any transmission is at the Customers’ own risk. Once the Company has received the Customer information it will use procedures and security features in an attempt to prevent unauthorized access. <br />
                <br />
                When Customers contact the Company, a person may be requested to provide some additional personal data, like their name or email address. Such data will be used to respond to their query and verify their identity. Emails are stored on Company’s standard internal contact systems which are secure and cannot be accessed by unauthorized external parties. <br />
            </p>
            <h5>6. MONITORING AND REVIEW</h5>
            <p>The Company will monitor on a regular basis the effectiveness of this Policy and, in particular, the execution quality of the procedures explained in the Policy and, where appropriate, it reserves the right to correct any deficiencies.<br />
                <br />
                The Company will review the Policy at least annually. A review will also be carried out whenever a material change occurs that affects the ability of the Company to continue to the best possible result for the execution of its Customer Orders on a consistent basis using the venues included in this Policy.<br />
                <br />
                The Company will inform its Customers of any material change to this Policy by posting an updated version of this Policy on its Website(s).
            </p>
            <h5>7.AMENDMENTS AND CONTACT DETAILS</h5>
            <p>The Company reserves the right to modify or amend this Privacy Statement unilaterally at any time in accordance with this provision. <br />
                <br />
                If any changes are made to this privacy statement, the Company shall notify the Customer accordingly. The revision date shown on at the end of this page will also be amended. The Company does however encourage the Customer to review this privacy statement occasionally so as to always be informed about how the Company is processing and protecting Customer’s personal information. <br />
                <br />
                The Customer has a right to lodge a complaint, If the Customer has a concern about the way the Company handles its personal information. <br />
                <br />
                Last update: January 01, 2024
            </p>
        </div>
    );
}
