import React, { useEffect } from 'react';
import Supercharge from './Adventures/Adventures';
import Manage from './Manage/OurWay';
import Write from './Write/Write';
import HowItWorks from './HowItWorks/HowItWorks';
import Solutions from './Solutions/Solutions';
import Happy from './Services/Services';
import Main from '../header/main/Main';
import styles from './styles.module.scss'
import ContactForm from '../ContactForm/ContactForm';
import 'aos/dist/aos.css';
import AOS from 'aos';

function HomePage() {
    useEffect(() => {
        window.scrollTo({ top: 0 });
        AOS.init({
            disable: false
        });
    }, [])

    return (
        <div data-aos="zoom-in" className={styles.container}>
            <Main />
            <div className={styles.container_fixed}>
                <Supercharge />
                <Manage />
                <Write />
                <HowItWorks />
                <Solutions />
                <Happy />
                <ContactForm />
            </div>
        </div>
    );
}

export default HomePage;



