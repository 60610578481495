import React, { useState } from 'react';
import style from './styles.module.scss';
import logo from '../../../assets/logo wallibou.svg';
import menu from '../../../assets/menu.svg';
import close from '../../../assets/close.svg';
import { Link } from 'react-router-dom';

function MobileHeader() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0 })
    }
    const [openMenu, setOpenMenu] = useState(false);
    const [signIn, setSignIN] = useState(false);
    function navFunc() {
        scrollToTop();
        setOpenMenu(!openMenu);
    }


    return (
        <div className={style.container}>
            <Link to='/'><img src={logo} alt="logo" onClick={scrollToTop} /></Link>
            <img src={menu} alt="menu" onClick={() => setOpenMenu(!openMenu)} />
            {openMenu ? <div className={style.modal}>
                <div className={style.menu}>
                    <div >
                        <Link to='/'><img src={logo} alt="logo" onClick={() => setOpenMenu(!openMenu)} /></Link>
                        <img onClick={() => setOpenMenu(!openMenu)} src={close} alt="close" />
                    </div>
                    <Link to='/services' onClick={navFunc}>Our services</Link>
                    <Link to='/integration' onClick={navFunc}>Integration</Link>
                    <Link to='/policies/terms' onClick={navFunc}>Policies</Link>
                    <Link to='/login'><button onClick={() => setOpenMenu(!openMenu)}>Sign In</button></Link>
                </div>
            </div> : null}
        </div>

    );
}

export default MobileHeader;