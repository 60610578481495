import styles from './styles.module.scss'
import React, { FC } from 'react';
import tickets from '../../../assets/travel-tickets-min.png'
import Luggage from '../../../assets/Luggage-min.png'
import map from '../../../assets/map-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const Payment: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);

    return (
        <div id="payment" className={styles.container}>
            <div data-aos="zoom-in" data-aos-delay={400} className={styles.container_top}>
                <div className={styles.img_wrap}><img src={tickets} alt="" /></div>
                <div className={styles.integration}>
                    <h2 >Payment gateway <img src={Luggage} alt="" /> <br />
                        integration</h2>
                    <span>Payment gateway integration is an essential step for any
                        business that wants to accept online payments. Payment
                        gateways are third-party services that facilitate secure
                        payment transactions between customers and merchants. By
                        integrating a payment gateway into your website or
                        application, you can securely process payments from
                        customers around the world.</span>
                </div>
            </div>
            <div data-aos="zoom-in" data-aos-delay={400} className={styles.container_botton}>
                <div id='recurring'>
                    <h2>Recurring billing <br /> options</h2>
                    <span>Recurring payment is a great choice for companies that offer
                        subscription services or products. With recurring payment,
                        your customers can set up automatic payments for your
                        services or products, ensuring timely payments and reducing
                        the risk of missed payments. This feature can also help you
                        improve customer retention and increase revenue by
                        providing a more convenient payment option.</span>
                </div>
                <div className={styles.img_wrap}><img src={map} alt="" /></div>
            </div>
        </div>
    );
}

export default Payment;