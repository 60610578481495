import styles from './styles.module.scss'
import { FC } from 'react';
import ship from '../../../assets/cruise-ship-min.png'
import anchor from '../../../assets/Anchor.png'
import { Link } from 'react-router-dom';
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Happy: FC = () => {


    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);

    return (
        <div data-aos="fade-up" data-aos-delay={300} className={styles.main}>
            <div className={styles.main_container}>
                <button className={styles.top_button}>Smart solution</button>
                <div className={styles.title_wrap}>
                    <h2>Our Services.</h2>
                    <img src={anchor} alt=''></img>
                </div>
                <div className={styles.main_options}>
                    <Link to='/services'>Payment gateway integration</Link>
                    <Link to='/services'>Recurring billing options</Link>
                    <Link to='/services'>Dispute resolution services</Link>
                    <Link to='/services'>Fraud detection and prevention measures</Link>
                    <Link to='/services'>Analytics and reporting tools</Link>
                </div>
            </div>
            <div className={styles.happy_shipWrap}>
                <img src={ship} alt='' />
            </div>
        </div>
    );
}

export default Happy;