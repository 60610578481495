import styles from './styles.module.scss'
import { FC } from 'react';
import hand from '../../../assets/Victory hand-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const Manage: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);

    return (
        <div data-aos="fade-up" data-aos-delay={400} className={styles.write}>
            <button className={styles.top_button}>Smart solution</button>
            <h2>Reliable worldwide payment
                <br />platform White Label.<img src={hand} alt=''></img></h2>
            <div style={{ width: '100%', height: '100%', position: 'relative', borderRadius: 60, overflow: 'hidden' }}>
                <div className={styles.task_1} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, left: 217, top: 280, position: 'absolute', background: '#89BA81', borderRadius: 20, width: 170, height: 20 }} />
                <div className={styles.task_2} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, left: 439.56, top: 200.21, position: 'absolute', transform: 'rotate(12.52deg)', transformOrigin: '0 0', background: '#FF9431', borderRadius: 20, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                    <p className={styles.task_text} style={{ color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word' }}>Flexible routing</p>
                </div>
                <div className={styles.task_3} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, left: 783, top: 280, position: 'absolute', background: '#AADDFF', borderRadius: 20, width: 220, height: 20 }} />
                <div className={styles.task_4} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, left: 335, top: 56, position: 'absolute', background: '#2D7CD8', borderRadius: 20, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                    <p className={styles.task_text} style={{ color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word' }}>Intelligent Transaction Routing</p>
                </div>
                <div className={styles.task_5} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, left: 775.34, top: 63, position: 'absolute', transform: 'rotate(33.95deg)', transformOrigin: '0 0', background: '#FFCB9E', borderRadius: 20, width: 200, height: 20 }} />
                <div className={styles.task_6} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, left: 64, top: 280.20, position: 'absolute', transform: 'rotate(-13.05deg)', transformOrigin: '0 0', background: '#374A80', borderRadius: 20, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                    <p className={styles.task_text} style={{ color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word' }}>High accuracy</p>
                </div>
            </div>
        </div>
    );
}

export default Manage;