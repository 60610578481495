import styles from './styles.module.scss'
import sun from '../../../assets/Sun.svg'
import like from '../../../assets/Image-min.png'
import settings from '../../../assets/fi-sr-settings.svg'
import layers from '../../../assets/fi-sr-layers.svg'
import rocket from '../../../assets/fi-sr-rocket.svg'
import following from '../../../assets/fi-sr-following.svg'
import watermelon from '../../../assets/palm-tree-min.png'
import worldMap from '../../../assets/WorldMap.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'


function Supercharge() {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);
    return (
        <div className={styles.supercharge}>
            <div data-aos="fade-up" data-aos-delay={300} className={styles.supercharge_title}>
                <img src={sun} className={styles.supercharge_sunImg} alt=''></img>
                <h2 className={styles.title}>Our company offers <br /> innovative solutions.</h2>
                <img src={like} className={styles.supercharge_likeImg} alt=''></img>
            </div>
            <div className={styles.main}>
                <div className={styles.features}>
                    <div data-aos="fade-up" data-aos-delay={300} className={styles.features_items}>
                        <img src={following} alt=''></img>
                        <div className={styles.features_items_text}>
                            <h5>3D Secure 2.0 (EMV 3DS)</h5>
                            <ul>
                                <li>Enhanced security.</li>
                                <li>Improved user experience.</li>
                                <li>Support for mobile devices.</li>
                                <li>Enhanced fraud protection.</li>
                            </ul>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay={300} className={styles.features_items}>
                        <img src={rocket} alt=''></img>
                        <div className={styles.features_items_text}>
                            <h5>Automated Onboarding</h5>
                            <p>It is a system that uses AI and machine learning algorithms to simplify and automate the process of onboarding new users to the platform. This service reduces the time and resources needed to integrate new users, while improving security and compliance. It includes functions such as security compliance control, fraud detection, and risk assessment.</p>
                        </div>
                    </div>
                </div>
                <div className={styles.features}>
                    <div data-aos="fade-up" data-aos-delay={300} className={styles.features_items}>
                        <img src={layers} alt=''></img>
                        <div className={styles.features_items_text}>
                            <h5>Transaction Routing</h5>
                            <p>The process of routing transactions from buyer to seller via various payment systems and service providers. The technology ensures fast and secure payment, as well as reduces the risks of fraud and system failures. It is used in e-commerce, banking, and other industries where there are a large number of payments.</p>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay={300} className={styles.features_items}>
                        <img src={settings} alt=''></img>
                        <div className={styles.features_items_text}>
                            <h5>Flexible Integration Methods</h5>
                            <p>Various applications and platforms, making payment more convenient and efficient for users. There is flexibility in choosing the integration method: API, SDK, or plugins, which allows you to integrate the payment system into any business process. This helps companies expand their payment options and attract new customers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-delay={300} className={styles.treeSection}>
                <div className={styles.treeWrap}>
                    <img src={watermelon} className={styles.tree} alt=''></img>
                </div>
                <div className={styles.contentWrap}>
                    <button>Smart solution</button>
                    <h2>Technology to <img src={worldMap} className={styles.mapImg} alt=''></img> simplify the payment process and make it secure.</h2>
                    <p>Our cutting-edge technology guarantees a significant improvement in protection levels, ensuring that your payments are as convenient and secure as possible.</p>
                </div>
            </div>
        </div>
    );
}

export default Supercharge;