import styles from './styles.module.scss'
import apps from '../../../assets/fi-sr-apps.svg'
import chart from '../../../assets/fi-sr-chart-pie.svg'
import customer from '../../../assets/fi-sr-customer focus.svg'
import pencil from '../../../assets/Pencil.png'
import { Link } from 'react-router-dom'

function Main() {
    return (
        <div className={styles.main}>
            <div className={styles.title_wrap}>
                <h1 className={styles.title}>Payment solutions <br /> for your business.</h1>
                <img src={pencil} className={styles.pencil} alt="pencil" />
                <svg className={styles.vector} xmlns="http://www.w3.org/2000/svg" width="259" height="17" viewBox="0 0 259 17" fill="none">
                    <path d="M0.999583 14.4007C65.0937 5.55783 166.5 -6 258 13.4999" stroke="#182F43" stroke-width="5" />
                </svg>
            </div>
            <h3>Our point is to share our technical expertise<br /> and  help your business grow!</h3>
            <div className={styles.button_blok}>
                <Link to='/contact'><button className={styles.dark}>Get Started</button></Link>

            </div>
            <div className={styles.items_blok}>
                <div className={styles.items}>
                    <img src={apps} alt='' className={styles.icons}></img>
                    <p>Safety</p>
                </div>
                <div className={styles.items}>
                    <img src={chart} alt='' className={styles.icons}></img>
                    <p>Uniqueness</p>
                </div>
                <div className={styles.items}>
                    <img src={customer} alt='' className={styles.icons}></img>
                    <p>Customer focus</p>
                </div>
            </div>
        </div>
    );
}

export default Main;
