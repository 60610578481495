import styles from './styles.module.scss'
import { FC } from 'react';

export const AKPolicy: FC = () => {


    return (
        <div className={styles.container}>
            <h3>AML & KYC Policy</h3>
            <h5>1.	GENERAL DEFINITION</h5>
            <p>Money laundering is the act of converting money or other material values gained from illegal activity (terrorism, drug dealing, illegal arms trade, corruption, human traffic and etc.) Into money or investments that appear to be legitimate. Such activity is used so that the illegal source of money and other material values cannot be traced.</p>
            <h5>2.	SCOPE OF REGULATION</h5>
            <p><span>AML/KYC Policy shall regulate following issues:</span>
                <br />
                <br />
                -	appointing of an Anti-Money Laundering Compliance Officer (AMLCO) and making sure that employees know how to report any suspicious activity to him; <br />
                -	identifying the responsibilities of senior managers and providing them with regular information on money laundering risks;<br />
                -	training relevant employees on their anti-money laundering responsibilities;<br />
                -	documenting and updating anti-money laundering policies, controls and procedures;<br />
                -	introducing measures to make sure that the risk of money laundering is taken into account in the day-to-day running of your business;<br />
                -	Forwarding/reporting all sustained suspicions to the relevant authority;<br />
                -	Promptly responding to all communication from the relevant authority.
            </p>
            <h5>3.	POLICY STATEMENT AND PRINCIPLES
            </h5>
            <p>Following procedures need to be in place and carried out before Wallibou can execute any transaction for a Customer: <br />
                -	AML procedures, namely identification, record-keeping, discovering and monitoring unusual or suspicious transactions and as appropriate internal reporting and control; <br />
                -	Employees know their responsibilities and Wallibou’s procedures; <br />
                -	Relevant training is being undertaken;<br />
                <br />
                Wallibou has adopted a program that complies with the aml/ctf act. This policy applies to all Wallibou officers, employees, appointed producers and products and services from Wallibou.<br />
                <br />
                All business units and locations within Wallibou will cooperate to create a cohesive effort in the fight against money laundering. Each business unit and location has implemented risk-based procedures reasonably expected to prevent, detect and cause the reporting of transactions required under the international anti-money laundering law.<br />
                <br />
                All efforts exerted will be documented and retained in accordance with the anti-money laundering law. The AML compliance committee is responsible for initiating suspicious activity reports (“sars”) or other required reporting to the appropriate law enforcement or regulatory agencies. Any contacts by law enforcement or regulatory agencies related to the policy shall be directed to the AML compliance committee.
            </p>
            <h5>5.	DATA REGISTRATION AND STORAGE </h5>
            <p>Wallibou shall ensure that Client and Transaction data are registered in Wallibou’s Client database within the required scope. <br />
                <span>Registration of data of a Client that is a legal person.</span><br />
                The following information concerning a Client that is a legal person shall be recorded:<br />
                a.	Name, legal form, registry code, address, date of registration and places of business;<br />
                b.	Information concerning means of communication and contact person(s);<br />
                c.	Field(s) of activity;<br />
                d.	Country of tax residency of the legal person (VAT number);<br />
                e.	Other relevant information having significance in the matter.
            </p>
            <h5>6.	SANCTION POLICY</h5>
            <p>Wallibou is required to implement the applicable International Sanctions.<br />
                Wallibou is prohibited from transacting with individuals, companies and countries that are under international sanctions.<br />
                Wallibou shall draw special attention to all its Clients (present and new), to the activities of the Clients and to the facts which refer to the possibility that the Client is a subject of International Sanctions. Control and verification of possibly imposed International Sanctions shall be conducted by the Representatives as part of DD measures applied to the Clients in accordance with this Policy.<br />
                In case of doubt, Wallibou shall ask the Client to provide additional information that may help to identify whether the Client is the subject of International Sanctions.<br />
                <br />
                Last update: January 01, 2024
            </p>
        </div>
    );
}
