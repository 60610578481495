import styles from './styles.module.scss'
import React, { FC } from 'react';
import plain from '../../../assets/airplane-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const HowItWorks: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);
    return (
        <div data-aos="fade-up" data-aos-delay={400} className={styles.container}>
            <div className={styles.container_title}>
                <button className={styles.top_button}>Smart solution</button>
                <h2>How it works?</h2>
            </div>
            <div className={styles.container_description}>
                <div className={styles.container_description_text}>
                    <div className={styles.container_description_item}>
                        <div className={styles.container_description_number}>1</div>
                        <div className={styles.container_description_li}>
                            <h5>Support 24/7</h5>
                            <p>Count on multilingual assistance <br />
                                professional 24 hours a day, <br />
                                7 days a week.
                            </p>
                        </div>
                    </div>
                    <div className={styles.container_description_item}>
                        <div className={styles.container_description_number}>2</div>
                        <div className={styles.container_description_li}>
                            <h5>Security</h5>
                            <p>We follow the strictest global <br />
                                safety standards and we operate <br />
                                in accordance with the applicable <br />regulations.</p>
                        </div>
                    </div>
                    <div className={styles.container_description_item}>
                        <div className={styles.container_description_number}>3</div>
                        <div className={styles.container_description_li}>
                            <h5>Single integration</h5>
                            <p>With just one API, your company <br />offers several payment methods <br /> for the final  consumer.</p>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                </div>
                <img src={plain} className={styles.plain} alt=''></img>
            </div>
        </div>
    );
}

export default HowItWorks;