import styles from './styles.module.scss'
import React, { FC, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import ThankYou from '../../ThankYou/ThankYou';

export const ApplicationForm: FC = () => {
    useEffect(() => emailjs.init("YOUR-PUBLIC-KEY-HERE"), []);
    const uNameRef = React.useRef<HTMLInputElement | any>();
    const uLastNameRef = React.useRef<HTMLInputElement | any>();
    const uEmailRef = React.useRef<HTMLInputElement | any>();
    const uPhoneRef = React.useRef<HTMLInputElement | any>();
    const cNameRef = React.useRef<HTMLInputElement | any>();
    const cRegistrationRef = React.useRef<HTMLInputElement | any>();
    const cCountryRef = React.useRef<HTMLInputElement | any>();
    const cPhoneRef = React.useRef<HTMLInputElement | any>();
    const cEmailRef = React.useRef<HTMLInputElement | any>();
    const [isClose, setIsClose] = useState(false);
    const [userName, setUserName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyRegistration, setCompanyRegistration] = useState('');
    const [companyCountry, setCompanyCountry] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value);
    };
    const handleUserLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserLastName(e.target.value);
    };
    const handleUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(e.target.value);
    };
    const handleUserPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserPhone(e.target.value);
    };
    const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(e.target.value);
    };
    const handleCompanyRegistrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyRegistration(e.target.value);
    };
    const handleCompanyCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyCountry(e.target.value);
    };
    const handleCompanyPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyPhone(e.target.value);
    };
    const handleCompanyEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyEmail(e.target.value);
    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const serviceId = "YOUR-SERVICE-ID-HERE";
        const templateId = "YOUR-TEMPLATE-ID-HERE";
        try {
            await emailjs.send(serviceId, templateId, {
                name: uNameRef.current.value,
                lastName: uLastNameRef.current.value,
                email: uEmailRef.current.value,
                phone: uPhoneRef.current.value,
                company: cNameRef.current.value,
                registration: cRegistrationRef.current.value,
                country: cCountryRef.current.value,
                comPhone: cPhoneRef.current.value,
                comEmail: cEmailRef.current.value
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsClose(true);
            setUserName('');
            setUserLastName('');
            setUserEmail('');
            setUserPhone('');
            setCompanyName('');
            setCompanyRegistration('');
            setCompanyCountry('');
            setCompanyPhone('');
            setCompanyEmail('');
            setIsClose(true);
        }
    };


    return (
        <div id='form' className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.form_merchant}>
                    <div className={styles.form_merchant_title}>
                        <h3 className={styles.form_title}>Merchant application form</h3>
                        <p>If you will have any difficulties in submitting the form, please send all necessary information to <span>info@wallibou.biz </span></p>
                    </div>
                    <div className={styles.form_merchant_details}>
                        <div className={styles.form_merchant_names}>
                            <div className={styles.form_merchant_first}>
                                <span className={styles.form_span}>First name</span>
                                <input className={styles.form_input} type="text" placeholder='Enter yuor first name' value={userName} onChange={handleUserNameChange} ref={uNameRef} />
                            </div>
                            <div className={styles.form_merchant_second}>
                                <span className={styles.form_span}>Last name</span>
                                <input className={styles.form_input} type="text" placeholder='Enter yuor last name' value={userLastName} onChange={handleUserLastNameChange} ref={uLastNameRef} />
                            </div>
                        </div>
                        <div className={styles.form_merchant_contacts}>
                            <div className={styles.form_merchant_first}>
                                <span className={styles.form_span}>Email</span>
                                <input className={styles.form_input} type="email" placeholder='Enter your email' value={userEmail} onChange={handleUserEmailChange} ref={uEmailRef} />
                            </div>
                            <div className={styles.form_merchant_second}>
                                <span className={styles.form_span}>Phone number</span>
                                <input className={styles.form_input} type="text" placeholder='Enter your phone number' value={userPhone} onChange={handleUserPhoneChange} ref={uPhoneRef} />
                                <span className={styles.form_span_number}>Please share your contact telephone number including area code</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.form_company}>
                    <div className={styles.form_company_title}>
                        <h3 className={styles.form_title_company}>Company Details</h3>
                        <span className={styles.span_normal}><span className={styles.span_bold}>Why do we ask about this?</span> According to anti-money laundering regulations we need to know who we are dealing with.</span>
                    </div>
                    <div className={styles.form_company_inputs}>
                        <div className={styles.company_name}>
                            <span className={styles.form_span}>Company name</span>
                            <input className={styles.form_input} type="text" placeholder='Enter your company name' ref={cNameRef} value={companyName} onChange={handleCompanyNameChange} />
                        </div>
                        <div className={styles.company_name}>
                            <span className={styles.form_span}>Registration number</span>
                            <input className={styles.form_input} type="text" placeholder='Enter your registration number' ref={cRegistrationRef} value={companyRegistration} onChange={handleCompanyRegistrationChange} />
                        </div>
                    </div>
                    <div className={styles.form_merchant_contacts}>
                        <div className={styles.form_merchant_first}>
                            <span className={styles.form_span}>Country</span>
                            <input className={styles.form_input} type="text" placeholder='Enter your Country' ref={cCountryRef} value={companyCountry} onChange={handleCompanyCountryChange} />
                        </div>
                        <div className={styles.form_merchant_second}>
                            <span className={styles.form_span}>Phone number</span>
                            <input className={styles.form_input} type="text" placeholder='Enter your phone number' ref={cPhoneRef} value={companyPhone} onChange={handleCompanyPhoneChange} />
                        </div>
                    </div>
                    <div className={styles.form_merchant_contacts}>
                        <div className={styles.form_merchant_first}>
                            <span className={styles.form_span}>Email</span>
                            <input className={styles.form_input} type="email" placeholder='Enter your email' ref={cEmailRef} value={companyEmail} onChange={handleCompanyEmailChange} />
                        </div>
                        <div className={styles.form_merchant_second}>

                        </div>
                    </div>

                </div>
                <button type='submit'>Submit Form</button>
            </form>
            {isClose ? <ThankYou close={() => setIsClose(false)} /> : null}
        </div>
    );
}

export default ApplicationForm;