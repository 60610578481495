import styles from './styles.module.scss'
import { FC } from 'react';
import direction from '../../../assets/direction-board-min.png'
import star from '../../../assets/GlowingStar.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const Dispute: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);
    return (
        <div data-aos="zoom-in" data-aos-delay={400} className={styles.container} id='dispute'>
            <div className={styles.container_top}>
                <div className={styles.integration}>
                    <h2>Dispute resolution <img src={star} alt="" /> <br />
                        services</h2>
                    <span id='recuring'>Recurring billing options are ideal for businesses that offer
                        subscription-based services or products. With recurring
                        billing, your customers can set up automatic payments for
                        your services or products, ensuring timely payments and
                        reducing the risk of missed payments. This feature can also
                        help you improve customer retention and increase revenue by
                        providing a more convenient payment option.</span>
                </div>
                <div className={styles.img_wrap}>
                    <img src={direction} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Dispute;