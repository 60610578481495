import './App.scss';
import Header from './components/header/Header';
import Footer from './components/Footer/Footer';
import HomePage from './components/Home/HomePage';
import ServicesPage from './components/Services/ServicesPage';
import IntegrationPage from './components/Integration/IntegrationPage';
import { Policies } from './components/Policies/Policies';
import { Routes, Route } from "react-router-dom";
import Cookies from './components/Cookies/Cookies';
import SignIn from './components/SignIn/SignIn';
import MobileHeader from './components/header/MobileHeader/MobileHeader';
import ApplicationForm from './components/Integration/ApplicationForm/ApplicationForm';

function App() {

  return (
    <div className="App">
      <Header />
      <MobileHeader />
      <Cookies />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/integration' element={<IntegrationPage />} />
        <Route path='/policies/*' element={<Policies />} />
        <Route path='/contact' element={<ApplicationForm />} />
        <Route path='/login' element={<SignIn />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
