import styles from './styles.module.scss'
import React, { FC } from 'react';
import tree from '../../../assets/DesertIsland.png'
import umbrella from '../../../assets/BeachUmbrella.png'
import wave from '../../../assets/Waterwave.png'
import Africa from '../../../assets/GlobeShowingEuropeAfrica.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const Services: FC = () => {
    useEffect(() => {
        AOS.init({
            disable: false
        });
    }, []);
    return (
        <div data-aos="fade-up" data-aos-delay={400} className={styles.container}>
            <div className={styles.container_title}>
                <button className={styles.top_button}>Smart solution</button>
                <h2>Wallibou allows the <br /> user to process card payments from: </h2>
                <div className={styles.botton_buttons}>
                    <p>Reliable partner </p>
                    <p>Technologies efficiency</p>
                    <p>High accuracy</p>
                </div>
            </div>
            <div className={styles.container_animated}>
                <span className={styles.animated_intern}>Maestro</span>
                <span className={styles.animated_june}>CUP<img src={umbrella} alt=''></img></span>
                <span className={styles.animated_medium}>Visa</span>
                <span className={styles.animated_general}>American Express</span>
                <span className={styles.animated_medium}>JCB<img src={tree} alt=''></img></span>
                <span className={styles.animated_june}>Mastercard<img src={Africa} alt=''></img></span>
                <span className={styles.animated_intern}>More<img src={wave} alt=''></img></span>
            </div>
        </div>
    );
}

export default Services;